import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { groupBy } from "underscore"
import Helmet from "react-helmet"
import Layout from "../components/layout"

import "../utils/css/awards.css"
import SEO from "../components/seo"

class awards extends React.Component {
  render() {
    const awardList = [
      { title: "Boston Architects", description: "The Best Interior Architects in Boston, Massachusetts", link:'https://www.bostonarchitects.org/the-best-interior-architects-in-boston-massachusetts/' },
  { title: "Boston Architects", description: "The Best Restaurant Architects and Designers in Boston", link:'https://www.bostonarchitects.org/best-restaurant-architects-boston/' },
      { title: "Boston Magazine", description: "Best of Boston" },
      { title: "Trip Advisor", description: "Certificate of Excellence Awards"},
      { title: "Open Table", description: "Diner's Choice Awards" },
      { title: "Nations Restaurant News", description: "Readers Pick Awards" },
      { title: "WGBH", description: "City Voter Awards" },
      { title: "Chicago Magazine", description: "Best of Chicago" },
      { title: "Phantom Gourmet", description: "Best Restaurant Awards" },
      { title: "Improper Boston", description: "Best of Boston Awards" },
      { title: "USA Today", description: "10 Best Awards" },
      { title: "Boston Herald", description: "Eat Out Awards" },
      { title: "AOL City Guide", description: "City's Best" },
      { title: "LUX Magazine", description: "Life Awards" },
      { title: "Orlando Magazine", description: "Best of Orlando" },
            // { title: "Eater" },
      { title: " Stuff Magazine" },
    ]
    let rows = awardList.length / 2
    let sortedList = []
    for (let i = 0; i < rows; i++) {
      sortedList.push(awardList.slice(i * 2, i * 2 + 2))
    }
    const { data } = this.props
    // console.log(data);
    const awardsObjArray =
      data.allSanityAwards &&
      data.allSanityAwards.edges.map(edge => {
        return {
          id: edge.node.id,
          title: edge.node.title,
          year: edge.node.year,
          description: edge.node.description,
        }
      })
    const awards = groupBy(awardsObjArray, "year")
    const years = Object.keys(awards)
    return (
      <Layout>
        <SEO
          title={`Awards|Architect|Restaurant Designer|Boston,MA`}
          description={`Whitlock Design Group is a leader in restaurant and hospitality design with an extensive portfolio of dynamic and innovative retail, restaurant and entertainment venues.Trillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium ${Math.random()}`}
        />

        <Helmet>
          <link rel="canonical" href="/awards" />
        </Helmet>
        <div className="nav-avoider" />
        <div className="awardsContainer">
          <div className="tabs">
            <input
              name="tabs"
              type="radio"
              id="tab-2"
              className="input"
              defaultChecked
            />
            <label htmlFor="tab-2" className="label">
              Client List
            </label>
            <div className="panel">
              <div className="row">
                <div className="newspaper">
                  <span>
                    {data.allSanityClient.edges.map((edge, index) => {
                      return (
                        <p
                          className="newspaper-child"
                          key={`${edge.node.id}-${index}`}
                        >
                          {edge.node.title}
                        </p>
                      )
                    })}
                  </span>
                </div>
              </div>
            </div>

            <input name="tabs" type="radio" id="tab-3" className="input" />
            <label htmlFor="tab-3" className="label">
              Press & Awards
            </label>
            <div className="panel">
              <div className="press-pics-wrapper">
                {/* {data.allSanityPress.edges.map((edge, index) => {
                  return (
                    <a
                      href={edge.node.website}
                      key={`${edge.node.website}-${index}`}
                    >
                      <div className="press-pic-holder">
                        <img
                          src={edge.node.image.asset.url}
                          className="press-pic"
                          alt="Interior design"
                          title="Interior design"
                        />
                      </div>
                    </a>
                  )
                })} */}
              </div>
              <h2>
                Our projects and clients have been featured in or recognized by
              </h2>
              <div className="container">
                {sortedList.map((awardRow, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {awardRow.map((award, j) => {
                        return (
                          <div className="wrapper" key={j}>
                            <p className="award">
                              {award.year && <span>{award.year} | </span>}
                              <a
                                href={award.link}
                                className="award-title"
                                target="_blank" // Opens links in a new tab
                                rel="noopener noreferrer" // Recommended for security
                              >
                                {award.title}
                              </a>
                              {award.description && (
                                <span>
                                  <span>&nbsp;|&nbsp;</span>
                                  <span className="award-description">
                                    {award.description}
                                  </span>
                                </span>
                              )}
                            </p>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </div>

            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default awards

export const query = graphql`
  query allPressClient {
    allSanityClient {
      edges {
        node {
          id
          title
        }
      }
    }

    allSanityAwards(sort: { order: DESC, fields: year }) {
      edges {
        node {
          id
          title
          year
          description
        }
      }
    }
  }
`
